exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-about-what-is-a-zine-js": () => import("./../../../src/pages/about/what-is-a-zine.js" /* webpackChunkName: "component---src-pages-about-what-is-a-zine-js" */),
  "component---src-pages-catalog-pdfs-js": () => import("./../../../src/pages/catalog/pdfs.js" /* webpackChunkName: "component---src-pages-catalog-pdfs-js" */),
  "component---src-pages-catalog-zines-accountability-consent-js": () => import("./../../../src/pages/catalog/zines/accountability-consent.js" /* webpackChunkName: "component---src-pages-catalog-zines-accountability-consent-js" */),
  "component---src-pages-catalog-zines-anti-oppression-js": () => import("./../../../src/pages/catalog/zines/anti-oppression.js" /* webpackChunkName: "component---src-pages-catalog-zines-anti-oppression-js" */),
  "component---src-pages-catalog-zines-direct-action-js": () => import("./../../../src/pages/catalog/zines/direct-action.js" /* webpackChunkName: "component---src-pages-catalog-zines-direct-action-js" */),
  "component---src-pages-catalog-zines-diy-js": () => import("./../../../src/pages/catalog/zines/diy.js" /* webpackChunkName: "component---src-pages-catalog-zines-diy-js" */),
  "component---src-pages-catalog-zines-history-js": () => import("./../../../src/pages/catalog/zines/history.js" /* webpackChunkName: "component---src-pages-catalog-zines-history-js" */),
  "component---src-pages-catalog-zines-js": () => import("./../../../src/pages/catalog/zines.js" /* webpackChunkName: "component---src-pages-catalog-zines-js" */),
  "component---src-pages-catalog-zines-legal-js": () => import("./../../../src/pages/catalog/zines/legal.js" /* webpackChunkName: "component---src-pages-catalog-zines-legal-js" */),
  "component---src-pages-catalog-zines-organizing-js": () => import("./../../../src/pages/catalog/zines/organizing.js" /* webpackChunkName: "component---src-pages-catalog-zines-organizing-js" */),
  "component---src-pages-catalog-zines-prisons-js": () => import("./../../../src/pages/catalog/zines/prisons.js" /* webpackChunkName: "component---src-pages-catalog-zines-prisons-js" */),
  "component---src-pages-catalog-zines-security-js": () => import("./../../../src/pages/catalog/zines/security.js" /* webpackChunkName: "component---src-pages-catalog-zines-security-js" */),
  "component---src-pages-catalog-zines-theory-js": () => import("./../../../src/pages/catalog/zines/theory.js" /* webpackChunkName: "component---src-pages-catalog-zines-theory-js" */),
  "component---src-pages-contact-submit-zine-tsx": () => import("./../../../src/pages/contact/submit-zine.tsx" /* webpackChunkName: "component---src-pages-contact-submit-zine-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("./../../../src/pages/contact-thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-privacy-tips-tsx": () => import("./../../../src/pages/privacy-tips.tsx" /* webpackChunkName: "component---src-pages-privacy-tips-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-submit-thanks-tsx": () => import("./../../../src/pages/submit-thanks.tsx" /* webpackChunkName: "component---src-pages-submit-thanks-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-zine-js": () => import("./../../../src/templates/zine.js" /* webpackChunkName: "component---src-templates-zine-js" */)
}

