module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/site-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff942d47ba0abe9db4fd3e9766a8371e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"crumbLabelUpdates":[{"pathname":"/about","crumbLabel":"About"},{"pathname":"/catalog","crumbLabel":"Catalog"},{"pathname":"/catalog/zines","crumbLabel":"Zines"},{"pathname":"/catalog/zines/accountability-consent","crumbLabel":"Accountability & Consent"},{"pathname":"/catalog/zines/anti-oppression","crumbLabel":"Anti-Oppression"},{"pathname":"/catalog/zines/direct-action","crumbLabel":"Direct Action"},{"pathname":"/catalog/zines/diy","crumbLabel":"DIY"},{"pathname":"/catalog/zines/history","crumbLabel":"History"},{"pathname":"/catalog/zines/legal","crumbLabel":"Legal"},{"pathname":"/catalog/zines/organizing","crumbLabel":"Organizing"},{"pathname":"/catalog/zines/prisons","crumbLabel":"Prisons"},{"pathname":"/catalog/zines/security","crumbLabel":"Security"},{"pathname":"/catalog/zines/theory","crumbLabel":"Theory"},{"pathname":"/contact","crumbLabel":"Contact"}],"trailingSlashes":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
